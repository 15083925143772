import React from "react";
import {css} from "@emotion/react";

import type {IMarker} from "@pg-mono/open-street-map";

import {IProps as IPoiTravelModeInfoWindowProps, PoiTravelModeInfoWindow} from "../components/PoiTravelModeInfoWindow";
import {IPoi} from "../types/IPoi";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType, TransportPoiType} from "./PoiType";

interface IOsmPoiMarkerPopupProps extends IPoiTravelModeInfoWindowProps {
    initiallyOpened?: boolean;
}

const defaultOsmPoiMarkerPopupProps: Partial<IOsmPoiMarkerPopupProps> = {
    calcTravelDataOnOpen: true
};

const MAX_DISTANCE_TO_FIT_IN_BOUNDS = 700;

export const createGetOsmPoiMarker =
    (setActivePoi: (poi: IPoi | IUserPoi | null, poiType: PoiType | null) => void, targetCoords?: [number, number]) =>
    (
        poi: IPoi | IUserPoi,
        poiType: PoiType,
        iconUrl: string,
        poiTravelModeProps?: Partial<IOsmPoiMarkerPopupProps>,
        poiSubType?: TransportPoiType
    ): IMarker => {
        const props = {...defaultOsmPoiMarkerPopupProps, poi, poiType, targetCoords, ...poiTravelModeProps};

        return {
            id: poi.id,
            coords: {
                lng: poi.lng,
                lat: poi.lat
            },
            icon: {
                url: iconUrl,
                sizes: [32, 32]
            },
            onClick: () => {
                setActivePoi(poi, poiType);
            },
            onInit: () => {
                if (poiTravelModeProps?.initiallyOpened) {
                    setActivePoi(poi, poiType);
                }
            },
            popup: (makerRef) => (
                <div css={popupWrapperStyle}>
                    <PoiTravelModeInfoWindow
                        onClose={() => {
                            makerRef?.current?.closePopup();
                        }}
                        {...props}
                    />
                </div>
            ),
            manualActive: () => {
                setActivePoi?.(poi, poiType);
            },
            skipInFitBounds: poi.distance > MAX_DISTANCE_TO_FIT_IN_BOUNDS,
            isPopupInitiallyOpened: poiType === PoiType.USER ? true : !!poiTravelModeProps?.initiallyOpened,
            poiType,
            poiSubType
        };
    };

const popupWrapperStyle = css`
    min-width: 24rem;
`;

import React, {useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {borderRadius, flex, flexAbsoluteCenter, mb, ml, mt, p, pb, pointer} from "@pg-design/helpers-css";
import {ChevronDownIcon, ChevronUpIcon, PlusIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {useIsMobile} from "@pg-mono/hooks";

import {poiAnalytics, PoiGTMModalAction} from "../tracking/poi_analytics";
import {IUserPoi} from "../types/IUserPoi";
import {UserPoiForm, UserPoiFormValuesType} from "./UserPoiForm";
import {UserPoiList} from "./UserPoiList";

interface IProps {
    offer?: {
        geo_point: {
            coordinates: [number, number];
        };
    };
    hideHeader?: boolean;
    disableCollapsible?: boolean;
    className?: string;
}

export const UserPoi = (props: IProps) => {
    const theme = useTheme();
    const isMobile = useIsMobile();

    const [collapsed, setCollapsed] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [userPoiToEdit, setUserPoiToEdit] = useState<UserPoiFormValuesType | null>(null);

    const targetCoords = props.offer?.geo_point.coordinates;

    const onUserPoiEdit = ({id, lat, lng, name, tags}: IUserPoi) => {
        setUserPoiToEdit({name, location: {label: tags.address, value: id, coordinates: [lat, lng]}});
        setShowForm(true);
    };

    const onHideForm = () => {
        setShowForm(false);
        setUserPoiToEdit(null);
    };

    const onAddUserPoi = () => {
        setShowForm(true);

        poiAnalytics.gtm.mapEvent({action: PoiGTMModalAction.CREATE_MY_POI_OPEN});
    };

    return (
        <div css={userPoi} className={props.className}>
            {props.hideHeader ? null : (
                <div css={heading}>
                    <Text as="span" variant="headline_6">
                        Moje miejsca
                    </Text>
                    {props.disableCollapsible ? null : (
                        <span css={toggleIcon} onClick={() => setCollapsed((prev) => !prev)}>
                            {collapsed ? <ChevronDownIcon size="2" /> : <ChevronUpIcon size="2" />}
                        </span>
                    )}
                </div>
            )}

            <div css={collapsed && !props.disableCollapsible ? hide : null}>
                {(!showForm || isMobile) && <UserPoiList onUserPoiEdit={onUserPoiEdit} targetCoords={targetCoords} />}

                {showForm ? (
                    <div css={userPoiForm}>
                        <UserPoiForm formInitialValues={userPoiToEdit} onHideForm={onHideForm} targetCoords={targetCoords} />
                    </div>
                ) : (
                    <div css={[flexAbsoluteCenter, pointer]} onClick={onAddUserPoi}>
                        <PlusIcon size="2" wrapperColor={theme.colors.primary} wrapperSize="2.4" />{" "}
                        <Text variant="button_small" css={ml()}>
                            Dodaj własne miejsce
                        </Text>
                    </div>
                )}
            </div>
        </div>
    );
};

const userPoi = (theme: Theme) => css`
    background-color: #fff;
    width: 100%;
    ${mt(6)};

    @media (min-width: ${theme.breakpoints.md}) {
        width: 26.4rem;
        ${elevation()};
        ${borderRadius(2)};
        ${p(2)};
        ${mt(0)};
    }
`;

const heading = css`
    ${flex("center", "space-between")};
    user-select: none;
    ${mb(3)};
`;

const toggleIcon = (theme: Theme) => css`
    cursor: pointer;

    @media (max-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const hide = css`
    height: 0;
    overflow: hidden;
`;

const userPoiForm = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        ${pb(4)};
        ${mb(3)};
    }
`;

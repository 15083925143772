import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {display} from "@pg-design/helpers-css";
import {CarOutlineIcon, PoiBusIcon, WalkIcon} from "@pg-design/icons-module";

import {IRPStore} from "../../app/rp_reducer";
import {formatDuration, TravelMode} from "../../property/utils/TravelMode";
import {setActivePoiDirections} from "../actions/set_poi_travel_directions";
import {useGooglePoiTravelDirections} from "../hooks/use_google_poi_travel_directions";
import {poiAnalytics, PoiGTMModalAction} from "../tracking/poi_analytics";
import {IPoi} from "../types/IPoi";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType} from "../utils/PoiType";
import {TravelModeButton} from "./TravelModeButton";

export interface IPoiTravelProps {
    calcTravelDataOnOpen?: boolean;
    listenToActivePoiDirections?: boolean;
    poi: IPoi | IUserPoi;
    poiType: PoiType;
    targetCoords?: [number, number];
}

const iconProps = {size: "1.6", fill: "#fff"};

const travelModeOptions = [
    {mode: TravelMode.DRIVING, label: "Car", icon: <CarOutlineIcon {...iconProps} />},
    {mode: TravelMode.WALKING, label: "Walk", icon: <WalkIcon {...iconProps} />},
    {mode: TravelMode.TRANSIT, label: "Bus", icon: <PoiBusIcon {...iconProps} />}
];

export const PoiTravelMode = (props: IPoiTravelProps) => {
    const dispatch = useDispatch();

    const {getPoiDirections} = useGooglePoiTravelDirections();

    const activePoi = useSelector((state: IRPStore) => state.maps.travelDirections.activePoi);
    const activePoiDirections = useSelector((state: IRPStore) => state.maps.travelDirections.activePoiDirections);
    const offerId = useSelector((state: IRPStore) => state.offerDetail.offer?.id);
    const poisDirections = useSelector((state: IRPStore) => state.maps.travelDirections.poisDirections);
    const propertyId = useSelector((state: IRPStore) => state.property.property?.id);
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const [selectedTravelModeData, setSelectedTravelModeData] = useState<{mode: null | TravelMode; duration: string | null}>({mode: null, duration: null});

    useEffect(() => {
        dispatch(setActivePoiDirections(null));
        // Local component state
        setSelectedTravelModeData({mode: null, duration: null});
    }, [props.poi.id, props.poi.distance]);

    useEffect(() => {
        if (props.listenToActivePoiDirections && activePoiDirections?.id === props.poi.id) {
            setSelectedTravelModeData({
                mode: activePoiDirections.travelMode,
                duration: formatDuration(poisDirections[props.poi.id][activePoiDirections.travelMode].duration, "m")
            });
        }
    }, [props.listenToActivePoiDirections, activePoiDirections, poisDirections]);

    useEffect(() => {
        if (props.poi.id === activePoi?.id && props.calcTravelDataOnOpen) {
            onTravelModeClick(TravelMode.DRIVING, true);
        }
    }, [props.calcTravelDataOnOpen, props.poi.id, activePoi?.id, setSelectedTravelModeData]);

    const onTravelModeClick = (mode: TravelMode, preventAnalytics = false) => {
        // During the first map opening auto directions action is performed - we don't need to track this event
        if (!preventAnalytics) {
            poiAnalytics.gtm.mapEvent({
                action: props.poiType === PoiType.USER ? PoiGTMModalAction.MY_POI_CALCULATE : PoiGTMModalAction.MAP_POI_CALCULATE,
                label: mode
            });
        }

        if (poisDirections[props.poi.id] && poisDirections[props.poi.id][mode]) {
            dispatch(setActivePoiDirections({id: props.poi.id, travelMode: mode, poiType: props.poiType}));
            // Local component state
            // If duration is less than 60 seconds return "1 m" value
            const duration = formatDuration(poisDirections[props.poi.id][mode].duration, "m") || "1 m";

            setSelectedTravelModeData({mode, duration});

            if (!preventAnalytics) {
                poiAnalytics.algolytics.meansOfTransportClick(viewType, props.poiType, props.poi, mode, parseInt(duration), offerId, propertyId);
            }

            return;
        }

        if (props.targetCoords) {
            getPoiDirections(props.poi, props.poiType, props.targetCoords, mode)
                .then((result) => {
                    if (result) {
                        // If duration is less than 60 seconds return "1 m" value
                        const duration = formatDuration(result.duration, "m") || "1 m";

                        setSelectedTravelModeData({mode, duration});

                        if (!preventAnalytics) {
                            poiAnalytics.algolytics.meansOfTransportClick(viewType, props.poiType, props.poi, mode, parseInt(duration), offerId, propertyId);
                        }
                    }
                })
                .catch(console.log);
        }
    };

    return (
        <div css={display("flex")}>
            {travelModeOptions.map((travelMode) => (
                <TravelModeButton
                    key={travelMode.mode}
                    travelMode={travelMode.mode}
                    selected={selectedTravelModeData.mode === travelMode.mode}
                    onClick={() => onTravelModeClick(travelMode.mode)}
                    duration={selectedTravelModeData.duration}
                />
            ))}
        </div>
    );
};

import {prefixPath} from "@pg-mono/prefix-path";

import {createLink} from "./utils/create_link";
import {createLinkWithScenario} from "./utils/create_link_with_scenario";
import {createNoParamLinkWithScenario} from "./utils/create_no_param_link_with_scenario";
import {GLUE_API_PREFIX, V2_API_PREFIX} from "./api_prefixes";

export const apiV2Path = prefixPath(V2_API_PREFIX, {
    articles: prefixPath("articles/", {
        article: prefixPath("article/", {
            detail: prefixPath(":articleId(\\d+)/", {
                base: "",
                rate: "rate/"
            })
        }),
        author: prefixPath("author/", {
            base: "",
            detail: ":authorId(\\d+)/"
        }),
        category: prefixPath("category/", {
            base: "",
            detail: ":categoryId(\\d+)/"
        }),
        top: prefixPath("top/", {
            base: ""
        })
    }),
    adverts: prefixPath("natives/", {
        unveiled: prefixPath("unveiled/", {
            detail: prefixPath(":unveiledId(\\d+)/", {
                base: ""
            })
        }),
        natives: prefixPath("natives/", {
            detail: prefixPath(":nativeId(\\d+)/", {
                base: ""
            })
        }),
        hero: prefixPath("hero/", {
            detail: prefixPath(":heroId(\\d+)/", {
                base: ""
            })
        }),
        tda: prefixPath("tda/", {
            detail: prefixPath(":tdaId(\\d+)/", {
                base: ""
            })
        })
    }),
    buildings: prefixPath("buildings/", {
        building: prefixPath("building/", {
            detail: prefixPath(":buildingId(\\d+)/", {
                base: ""
            })
        })
    }),
    floors: prefixPath("floors/", {
        floor: prefixPath("floor/", {
            detail: prefixPath(":floorId(\\d+)/", {
                base: ""
            })
        })
    }),
    offers: prefixPath("offers/", {
        offer: prefixPath("offer/", {
            detail: prefixPath(":offerId(\\d+)/", {
                base: ""
            })
        }),
        similar: prefixPath("offer/:offerId(\\d+)/similar/", {
            base: ""
        })
    }),
    partners: prefixPath("partners/", {
        base: "",
        partner: prefixPath("partner/", {
            list: ""
        })
    }),
    ratings: prefixPath("ratings/", {
        quarters: "quarters/",
        partners: "partners/"
    }),
    regions: prefixPath("regions/", {
        region: prefixPath("region/", {
            base: "",
            detail: prefixPath(":regionId(\\d+)/", {
                base: "",
                voivodeship: "voivodeship_top_cities/"
            })
        })
    }),
    reels: prefixPath("reels/reel/", {
        details: prefixPath(":reelId(\\d+)/", {
            base: ""
        })
    }),
    promotions: prefixPath("promotions/", {
        statistic: prefixPath("statistic/", {
            base: ""
        })
    }),
    properties: prefixPath("properties/", {
        stats: "stats/",
        budget: "price-stats/",
        configurator: "configurator/",
        property: prefixPath("property/", {
            base: "",
            detail: prefixPath(":propertyId(\\d+)/", {
                base: ""
            })
        }),
        similar: prefixPath("property/:propertyId(\\d+)/similar/", {
            base: ""
        })
    }),
    stats: prefixPath("stats/", {
        offers: "offers"
    }),
    termsOfService: prefixPath("terms-of-service/", {
        terms: prefixPath("terms/", {
            detail: prefixPath(":termsSlug", {
                base: ""
            })
        })
    }),
    vendors: prefixPath("vendors/", {
        vendor: prefixPath("vendor/", {
            base: "",
            detail: prefixPath(":vendorId(\\d+)/", {
                base: ""
            })
        })
    }),
    faq: prefixPath("faq/", {
        base: ""
    }),
    metadata: prefixPath("metadata/", {
        page: "page/"
    })
});

export const apiV2ListPath = prefixPath(GLUE_API_PREFIX, {
    articles: prefixPath("articles/", {
        base: "",
        article: prefixPath("article/", {
            list: ""
        }),
        authors: prefixPath("author/", {
            list: ""
        }),
        category: prefixPath("category/", {
            base: ""
        })
    }),
    buildings: prefixPath("buildings/", {
        building: prefixPath("building/", {
            list: ""
        })
    }),
    comments: prefixPath("comments/", {
        base: "",
        article: prefixPath("article/", {
            base: ""
        }),
        comment: prefixPath("comment/", {
            list: ""
        })
    }),
    floors: prefixPath("floors/", {
        floor: prefixPath("floor/", {
            list: ""
        })
    }),
    properties: prefixPath("properties/", {
        base: "",
        configurator: "configurator/",
        property: prefixPath("property/", {
            list: ""
        })
    }),
    offers: prefixPath("offers/", {
        base: "",
        offer: prefixPath("offer/", {
            list: ""
        })
    }),
    recommendations: prefixPath("recommendations/", {
        reference: prefixPath("reference/", {
            list: ""
        })
    }),
    promotions: prefixPath("promotions/", {
        promotion: prefixPath("promotion/", {
            list: ""
        })
    }),
    ratings: prefixPath("ratings/", {
        quarterly: "quarterly/",
        cities: prefixPath("cities/", {
            list: ""
        }),
        monthly: prefixPath("monthly/", {
            list: ""
        }),
        annual: prefixPath("annual/", {
            list: ""
        })
    }),
    reels: prefixPath("reels/reel/", {
        list: ""
    }),
    regions: prefixPath("regions/", {
        base: "",
        vendor: prefixPath("vendor-listing/", {
            list: ""
        }),
        region: prefixPath("region/", {
            list: ""
        })
    }),
    vendors: prefixPath("vendors/", {
        vendor: prefixPath("vendor/", {
            base: ""
        })
    })
});

export const apiV2Link = {
    author: {
        detail: createLinkWithScenario<Scenario.AUTHOR_DETAIL, {authorId: number}>(apiV2Path.articles.author.detail)
    },
    article: {
        detail: createLinkWithScenario<Scenario.ARTICLE_DETAIL, {articleId: number}>(apiV2Path.articles.article.detail.base),
        top: createNoParamLinkWithScenario<Scenario.TOP_ARTICLE_LIST>(apiV2Path.articles.top.base)
    },
    category: {
        detail: createLinkWithScenario<Scenario.CATEGORY_DETAIL, {categoryId: number}>(apiV2Path.articles.category.detail)
    },
    adverts: {
        natives: {
            detail: createLink<{nativeId: number}>(apiV2Path.adverts.natives.detail.base)
        },
        hero: {
            detail: createLink<{heroId: number}>(apiV2Path.adverts.hero.detail.base)
        },
        unveiled: {
            detail: createLink<{unveiledId: number}>(apiV2Path.adverts.unveiled.detail.base)
        },
        tda: {
            detail: createLink<{tdaId: number}>(apiV2Path.adverts.tda.detail.base)
        }
    },
    building: {
        detail: createLinkWithScenario<Scenario.BUILDING_DETAIL, {buildingId: number}>(apiV2Path.buildings.building.detail.base)
    },
    floor: {
        detail: createLinkWithScenario<Scenario.FLOOR_DETAIL, {floorId: number}>(apiV2Path.floors.floor.detail.base)
    },
    offer: {
        detail: createLinkWithScenario<IOfferDetailScenarios, {offerId: number}>(apiV2Path.offers.offer.detail.base),
        similar: createLink<{offerId: number}>(apiV2Path.offers.similar.base),
        pictures: createLinkWithScenario<IOfferDetailScenarios, {offerId: number}>(apiV2Path.offers.offer.detail.base)
    },
    recommendations: {
        list: createNoParamLinkWithScenario<Scenario.REFERENCE_LIST>(apiV2ListPath.recommendations.reference.list)
    },
    partners: {
        list: createLink(apiV2Path.partners.partner.list)
    },
    region: {
        detail: createLinkWithScenario<Scenario.REGION_DETAIL | Scenario.SEARCH_REGION_DETAIL | Scenario.SEARCH_REGION_PARENT, {regionId: number}>(
            apiV2Path.regions.region.detail.base
        ),
        voivodeship: createLink<{regionId: number}>(apiV2Path.regions.region.detail.voivodeship)
    },
    vendor: {
        detail: createLinkWithScenario<Scenario.VENDOR_DETAIL_APPLICATION | Scenario.VENDOR_DETAIL, {vendorId: number}>(apiV2Path.vendors.vendor.detail.base)
    },
    stats: {
        offers: createLink(apiV2Path.stats.offers)
    },
    promotion: {
        statistic: createLink(apiV2Path.promotions.statistic.base)
    },
    property: {
        budget: createLink(apiV2Path.properties.budget),
        configurator: createNoParamLinkWithScenario<Scenario.PROPERTY_CONFIGURATOR_LIST>(apiV2Path.properties.configurator),
        list: createNoParamLinkWithScenario<Scenario.PROPERTY_LIST | Scenario.PROPERTY_COMPARISON>(apiV2Path.properties.property.base),
        stats: createLink(apiV2Path.properties.stats),
        detail: createLinkWithScenario<Scenario.PROPERTY_DETAIL | Scenario.PROPERTY_APPLICATION, {propertyId: number}>(
            apiV2Path.properties.property.detail.base
        ),
        similar: createLink<{propertyId: number}>(apiV2Path.properties.similar.base),
        pictures: createLinkWithScenario<Scenario.PROPERTY_DETAIL_GALLERY, {propertyId: number}>(apiV2Path.properties.property.detail.base)
    },
    ratings: {
        cities: {
            list: createNoParamLinkWithScenario<Scenario.RATINGS_CITIES_LIST>(apiV2ListPath.ratings.cities.list)
        },
        annual: {
            list: createNoParamLinkWithScenario<Scenario.RATINGS_ANNUAL_LIST>(apiV2ListPath.ratings.annual.list)
        },
        monthly: {
            list: createNoParamLinkWithScenario<Scenario.RATINGS_MONTHlY_LIST>(apiV2ListPath.ratings.monthly.list)
        },
        quarterly: {
            list: createNoParamLinkWithScenario<Scenario.RATINGS_QUARTER_LIST>(apiV2ListPath.ratings.quarterly)
        }
    },
    terms: {
        detail: createLink<{termsSlug: string}>(apiV2Path.termsOfService.terms.detail.base)
    },
    metadata: createLink(apiV2Path.metadata.page)
};

export const apiV2ListLink = {
    article: {
        list: createNoParamLinkWithScenario<Scenario.SITEMAP_ARTICLE_LIST | Scenario.ARTICLE_LIST | Scenario.OFFER_ARTICLE_LIST>(
            apiV2ListPath.articles.article.list
        ),
        category: createNoParamLinkWithScenario<Scenario.SITEMAP_ARTICLE_CATEGORIES>(apiV2ListPath.articles.category.base),
        authors: {
            list: createNoParamLinkWithScenario<Scenario.AUTHOR_LIST>(apiV2ListPath.articles.authors.list)
        }
    },
    buildings: {
        list: createNoParamLinkWithScenario<Scenario.BUILDING_PLAN>(apiV2ListPath.buildings.building.list)
    },
    comments: {
        list: createLinkWithScenario<Scenario.ARTICLE_DETAIL_COMMENTS, {articleId: number}>(apiV2ListPath.comments.comment.list)
    },
    floors: {
        list: createNoParamLinkWithScenario<Scenario.FLOOR_DETAIL>(apiV2ListPath.floors.floor.list)
    },
    property: {
        configurator: createNoParamLinkWithScenario<Scenario.PROPERTY_CONFIGURATOR_LIST>(apiV2ListPath.properties.configurator),
        list: createNoParamLinkWithScenario<Scenario.PROPERTY_LIST | Scenario.PROPERTY_COMPARISON | Scenario.PROPERTY_DETAIL>(
            apiV2ListPath.properties.property.list
        )
    },
    ratings: {
        quarterly: createLinkWithScenario<Scenario.RATINGS_VENDOR_DETAIL, {vendorId: number}>(apiV2ListPath.ratings.quarterly)
    },
    offer: {
        list: createNoParamLinkWithScenario<IOfferListScenarios>(apiV2ListPath.offers.offer.list)
    },
    promotion: {
        list: createNoParamLinkWithScenario<Scenario.PROMOTION_LIST>(apiV2ListPath.promotions.promotion.list)
    },
    reels: {
        list: createNoParamLinkWithScenario<Scenario.REEL_LIST>(apiV2ListPath.reels.list),
        details: createLinkWithScenario<Scenario.REEL_DETAIL, {reelId: number}>(apiV2Path.reels.details.base)
    },
    region: {
        list: createNoParamLinkWithScenario<IRegionScenarios>(apiV2ListPath.regions.region.list),
        vendor: {
            list: createNoParamLinkWithScenario<IRegionScenarios>(apiV2ListPath.regions.vendor.list)
        }
    },
    vendor: {
        list: createNoParamLinkWithScenario<Scenario>(apiV2ListPath.vendors.vendor.base)
    },
    faq: {
        list: createLink(apiV2Path.faq.base)
    }
};

export enum Scenario {
    ARTICLE_DETAIL = "article-detail",
    ARTICLE_DETAIL_COMMENTS = "article-detail-comments",
    ARTICLE_LIST = "article-list",
    AUTHOR_DETAIL = "author-detail",
    AUTHOR_LIST = "author-list",
    BUILDING_DETAIL = "building-detail",
    BUILDING_PLAN = "building-plan",
    CATEGORY_DETAIL = "category-detail",
    FLOOR_DETAIL = "floor-detail",
    OFFER_APPLICATION = "offer-application",
    OFFER_ARTICLE_LIST = "offer-article-list",
    OFFER_DETAIL = "offer-detail",
    OFFER_LIST = "offer-list",
    OFFER_LIST_RELATED = "offer-list-related",
    OFFER_DETAIL_MAP = "offer-detail-map",
    OFFER_DETAIL_POIS = "offer-detail-pois",
    OFFER_DETAIL_POI_STATS = "offer-detail-poi-stats",
    OFFER_LIST_MAP = "offer-list-map",
    OFFER_REGION_SLUG = "offer-region-slug",
    SEARCH_OFFER_LIST = "search-offer-list",
    SITEMAP_ARTICLE_LIST = "sitemap-article-list",
    SITEMAP_ARTICLE_CATEGORIES = "sitemap-article-categories",
    SITEMAP_OFFER_LIST = "sitemap-offer-list",
    SITEMAP_REGION_BY_TYPE = "region-sitemap-by-type",
    SITEMAP_REGION_BY_SLUG = "region-sitemap-by-slug",
    SITEMAP_VENDOR_LIST = "sitemap-vendor-list",
    PROMOTION_LIST = "promotion-list",
    PROPERTY_APPLICATION = "property-application",
    PROPERTY_DETAIL = "property-detail",
    PROPERTY_CONFIGURATOR_LIST = "property-configurator-list",
    PROPERTY_LIST = "property-list",
    PROPERTY_COMPARISON = "property-comparison",
    REEL_LIST = "reel-list",
    REEL_DETAIL = "reel-detail",
    REGION_DETAIL = "region-detail",
    REGION_LIST = "region-list",
    REGION_REPORT_PRICE = "region-report-price",
    SEARCH_REGION_DETAIL = "search-region-detail",
    SEARCH_REGION_PARENT = "search-region-parent",
    RATINGS_VENDOR_DETAIL = "ratings-vendor-detail",
    RATINGS_ANNUAL_LIST = "ratings-annual-list",
    RATINGS_MONTHlY_LIST = "ratings-monthly-list",
    RATINGS_QUARTER_LIST = "ratings-quarter-list",
    RATINGS_CITIES_LIST = "ratings-cities-list",
    REGION_LIST_MAP = "region-list-map",
    REGION_DETAIL_MAP = "region-detail-map",
    REFERENCE_LIST = "reference-list",
    TOP_ARTICLE_LIST = "top-article-list",
    VENDOR_DETAIL = "vendor-detail",
    VENDOR_DETAIL_OFFER_LIST = "vendor-detail-offer-list",
    VENDOR_DETAIL_APPLICATION = "vendor-detail-application",
    VENDOR_LIST = "vendor-list",
    VENDOR_LIST_REGION = "region-list-vendor",
    VENDOR_DETAIL_CITIES_LIST = "vendor-detail-cities-list",
    OFFER_DETAIL_GALLERY = "offer-detail-gallery",
    PROPERTY_DETAIL_GALLERY = "property-detail-gallery"
}

type IRegionScenarios =
    | Scenario.REGION_LIST
    | Scenario.SEARCH_REGION_DETAIL
    | Scenario.SEARCH_REGION_PARENT
    | Scenario.REGION_LIST_MAP
    | Scenario.SITEMAP_REGION_BY_TYPE
    | Scenario.SITEMAP_REGION_BY_SLUG
    | Scenario.VENDOR_LIST
    | Scenario.VENDOR_LIST_REGION
    | Scenario.VENDOR_DETAIL_CITIES_LIST
    | Scenario.REGION_REPORT_PRICE
    | Scenario.REGION_DETAIL; // TODO: REGION_DETAIL is temporary

type IOfferDetailScenarios =
    | Scenario.OFFER_DETAIL
    | Scenario.OFFER_DETAIL_MAP
    | Scenario.OFFER_DETAIL_POIS
    | Scenario.OFFER_DETAIL_POI_STATS
    | Scenario.OFFER_REGION_SLUG
    | Scenario.OFFER_APPLICATION
    | Scenario.OFFER_DETAIL_GALLERY;

type IOfferListScenarios =
    | Scenario.OFFER_LIST
    | Scenario.OFFER_LIST_RELATED
    | Scenario.OFFER_LIST_MAP
    | Scenario.SITEMAP_OFFER_LIST
    | Scenario.VENDOR_DETAIL_OFFER_LIST
    | Scenario.PROPERTY_CONFIGURATOR_LIST;

import React, {useRef, useState} from "react";
import {useField} from "formik";

import {display} from "@pg-design/helpers-css";
import {FieldWrapper} from "@pg-design/inputs";
import {IOption, SelectAsync} from "@pg-design/select";
import {loadGoogleMapsApi} from "@pg-mono/google-api";

import {fetchDebouncedSearchGooglePlaces} from "../../search/actions/fetch_search_places_action";

type PlaceResult = google.maps.places.PlaceResult;

interface IProps {
    name: string;
    placeholder?: string;
    labelContent?: string | JSX.Element;
    allowEditLastValue?: boolean;
    disableDropdownIndicator?: boolean;
    className?: string;
}

export interface IPlacesAutocompleteValue {
    label?: string;
    value?: string | number;
    coordinates: number[];
}

export const PlacesAutocomplete = (props: IProps) => {
    const {name, placeholder, labelContent, className} = props;
    const [field, meta, helpers] = useField<IPlacesAutocompleteValue>(name);
    const [inputValue, setInputValue] = useState<string>("");

    const hasValue = field.value.value && field.value.label;

    const onPlaceSelect = async (selectedPlace: IOption) => {
        await loadGoogleMapsApi(["places"]);

        const googlePlacesTabResultsElement = document.getElementById("poiAutocomplete");
        const service = new google.maps.places.PlacesService(googlePlacesTabResultsElement as HTMLDivElement);

        service.getDetails({placeId: selectedPlace.value as string}, (placeDetail: PlaceResult | null) => {
            if (placeDetail && placeDetail?.geometry?.location) {
                const lat = placeDetail.geometry?.location.lat();
                const lng = placeDetail.geometry?.location.lng();

                helpers.setValue({...selectedPlace, coordinates: [lat, lng]});
            }
        });
    };

    const ref = useRef<HTMLDivElement>(null);
    const onMenuOpen = () => {
        if (props.allowEditLastValue) {
            // set last option label to allow editing
            setInputValue(field.value.label || "");
        }
    };

    // set last option only if editing is allowed, otherwise it just be an empty input with no options
    const defaultOptions =
        props.allowEditLastValue && field.value.label && field.value.value
            ? [
                  {
                      label: field.value.label,
                      value: field.value.value
                  }
              ]
            : undefined;

    return (
        <div className={className} ref={ref}>
            <FieldWrapper
                message={meta.error && meta.touched ? meta.error : ""}
                fieldState={meta.error && meta.touched ? "error" : "default"}
                labelContent={labelContent}
            >
                <SelectAsync
                    disableDropdownIndicator={props.disableDropdownIndicator}
                    inputValue={inputValue}
                    onInputChange={setInputValue}
                    onMenuOpen={onMenuOpen}
                    defaultOptions={defaultOptions}
                    name={name}
                    placeholder={placeholder ? placeholder : "Lokalizacja"}
                    loadOptions={(input: string) => fetchDebouncedSearchGooglePlaces(input, field.value)}
                    onChange={(selectedPlace) => onPlaceSelect(selectedPlace as unknown as IOption)}
                    value={hasValue ? field.value : undefined}
                    noOptionsText="Wpisz dokładny adres (np. Adama Naruszewicza 27)"
                />
            </FieldWrapper>

            <div css={display("none")} id="poiAutocomplete" />
        </div>
    );
};

import React from "react";
import {css, Theme} from "@emotion/react";

import {mv} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons-module";

interface IProps {
    onClose?: () => void;
    offer: {
        address: string;
        name: string;
        vendor: {
            name: string;
        };
    };
}

export const OfferInfoWindow = (props: IProps) => {
    return (
        <div css={offerInfoWindow}>
            {props.onClose && (
                <span css={closePosition} onClick={props.onClose}>
                    <CloseIcon size="1" />
                </span>
            )}

            <div css={heading} className="bt">
                {props.offer.name}
            </div>
            <div css={mv()} className="bt">
                {props.offer.address}
            </div>
            <div className="bt">{props.offer.vendor.name}</div>
        </div>
    );
};

const offerInfoWindow = css`
    background-color: #fff;
    border-radius: 0.4rem;
    padding: 2rem;
`;

const closePosition = (theme: Theme) => css`
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    @media (max-width: ${theme.breakpoints.md}) {
        right: 1.5rem;
    }
`;

const heading = css`
    font-size: 2.2rem;
    font-weight: 500;
`;

import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {css, Theme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {borderRadius, flex, mb, mt, p} from "@pg-design/helpers-css";
import {ChevronDownIcon, ChevronUpIcon} from "@pg-design/icons-module";
import {Switcher} from "@pg-design/switcher-module";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../app/rp_reducer";
import {setActivePoiDirections} from "../actions/set_poi_travel_directions";
import {poiAnalytics, PoiGTMModalAction} from "../tracking/poi_analytics";
import {PoiType} from "../utils/PoiType";

const poiTypes = [
    {type: PoiType.TRANSPORT, label: "Komunikacja"},
    {type: PoiType.EDUCATION, label: "Edukacja"},
    {type: PoiType.SHOPS, label: "Sklepy"},
    {type: PoiType.SPORT, label: "Sport"},
    {type: PoiType.HEALTH, label: "Zdrowie"},
    {type: PoiType.ENTERTAINMENT, label: "Place zabaw"},
    {type: PoiType.FOOD, label: "Kawiarnie i restauracje"}
];

interface IProps {
    checkedPoiTypes: PoiType[];
    onChange: (checkedPoiTypes: PoiType[]) => void;
    hideHeader?: boolean;
    disableCollapsible?: boolean;
    className?: string;
}

export const PoiSwitcher = (props: IProps) => {
    const dispatch = useDispatch();
    const params = useParams<{offerId?: string; propertyId?: string}>();

    const [collapsed, setCollapsed] = useState(false);

    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const togglePoiType = (type: string, checked: boolean) => {
        /*
         * Because our current implementation of rendering markers on the map re-renders marker each time when we are
         * adding or removing records (markers) (so marker InfoWindow is being closed) we need to remove polylines from the map.
         */
        dispatch(setActivePoiDirections(null));

        props.onChange(checked ? props.checkedPoiTypes.concat(type as PoiType) : props.checkedPoiTypes.filter((item) => item !== type));

        poiAnalytics.gtm.mapEvent({action: checked ? PoiGTMModalAction.IMPORTANT_PLACES_ON : PoiGTMModalAction.IMPORTANT_PLACES_OFF, label: type});
        poiAnalytics.algolytics.showPoi(viewType, checked, type as PoiType, params.offerId, params.propertyId);
    };

    return (
        <div css={poiSwitcher} className={props.className}>
            {props.hideHeader ? null : (
                <div css={heading}>
                    <Text as="span" variant="headline_6">
                        Ważne miejsca
                    </Text>
                    {props.disableCollapsible ? null : (
                        <span css={toggleIcon} onClick={() => setCollapsed((prev) => !prev)}>
                            {collapsed ? <ChevronDownIcon size="2" /> : <ChevronUpIcon size="2" />}
                        </span>
                    )}
                </div>
            )}

            {(!collapsed || props.disableCollapsible) && (
                <div>
                    {poiTypes.map((poiType) => {
                        const checked = props.checkedPoiTypes.includes(poiType.type);

                        return (
                            <div key={poiType.type} css={[poiTypeItem]}>
                                <Text variant="info_txt_1" as="span">
                                    {poiType.label}
                                </Text>

                                <Switcher id={poiType.type} checked={checked} onChange={(checked) => togglePoiType(poiType.type, checked)} labelContent="" />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const poiSwitcher = (theme: Theme) => css`
    background-color: #fff;
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
        width: 26.4rem;
        ${elevation()};
        ${borderRadius(2)};
        ${p(2)};
    }
`;

const heading = (theme: Theme) => css`
    ${mt(4)};
    ${mb(3)};
    ${flex("center", "space-between")};
    user-select: none;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mt(0)};
    }
`;

const toggleIcon = (theme: Theme) => css`
    cursor: pointer;

    @media (max-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const poiTypeItem = (theme: Theme) => css`
    user-select: none;
    ${mb(2)};
    ${flex("center", "space-between")}

    &:last-of-type {
        ${mb(0)};
    }

    @media (max-width: ${theme.breakpoints.md}) {
        margin-bottom: 2rem;
    }
`;
